<script setup>
const props = defineProps({
    bigImageUrl: String,
});
const emit = defineEmits(["update:bigImageUrl"]);
</script>

<template>
    <section class="viewer-widget" @click.stop="emit('update:bigImageUrl', '')">
        <img :src="bigImageUrl" alt="Image Viewer" />
    </section>
</template>

<style lang="less" scoped>
.viewer-widget {
    .dialog();
    padding: 10px;
    z-index: 111;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: zoom-out;

    img {
        display: block;
        max-width: 60vw;
        max-height: 100%;
        min-width: 200px;
        min-height: 60vh;
        border-radius: 8px;
        object-fit: contain;
        background-color: white;
    }
}
.fade(0.3s);
</style>
