// 从v2.11.2起，SDK 支持了 WebSocket，推荐接入；v2.10.2及以下版本，使用 HTTP
import TencentCloudChat from "@tencentcloud/chat"; //"tim-js-sdk";
import TencentCloudChatUploadPlugin from "tim-upload-plugin";
// import TIMProfanityFilterPlugin from "tim-profanity-filter-plugin";
import MessageHandler from "./message-handler";
import { setCustomerServiceAccounts } from "@tencentcloud/tui-customer-service-plugin";
import { TUILogin } from '@tencentcloud/tui-core';
export default class IM {
    static noop() {}
    constructor() {
        this.store = markRaw(appStore());
        this.tim = markRaw({});

        this.initDone = false;
        this.initEventDone = false;

        this.isSdkReady = false; //TencentCloudChat是否已登录
        this.loginStatus = null; //登录状态 promise  //用于登录过程中需要绑定事件，isSdkReady不能体现登录过程
        this.readyResolve = IM.noop;
        this.readyReject = IM.noop;
        // this.cacheFollowTypeList = new Map(); 缓存用户关注关系列表
    }
    // 发送自定义群消息
    sendGroupCustomMessage(groupID, payload) {
        let message = this.tim.createCustomMessage({
            to: groupID,
            conversationType: TencentCloudChat.TYPES.CONV_GROUP,
            payload,
        });
        // 发送消息
        let promise = this.tim.sendMessage(message);
        return promise
            .then(imResponse => {
                // 发送成功
                MessageHandler([imResponse.data.message], this.store.currentGroupID);
                // this.store.Log.addImLog({description:'接收消息成功',content:imResponse.data.message})
                return imResponse;
            })
            .catch(imError => {
                // this.store.Log.addImLog({description:'发送自定义消息失败',content:payload.source || '',error:imError})
                // 发送失败
                if (imError) {
                    switch (imError.code) {
                        case 10017: //禁言
                            Toast({
                                message: "您已被禁言",
                                teleport: ".message-channel",
                                className: "f-pa",
                            });
                            break;
                        case 80001: //云端审核拦截
                            Toast({
                                message: "内容违规！",
                                teleport: ".message-channel",
                                className: "f-pa",
                            });
                            break;
                    }
                }
                // console.log("error :>> ", imError);
            });
    }

    //发送私聊自定义消息
    async sendC2CCustomMessage(toUser, payload, created = () => {}) {
        if (!this.isSdkReady) return Promise.reject();
        const message = this.tim.createCustomMessage({
            to: toUser,
            conversationType: TencentCloudChat.TYPES.CONV_C2C,
            payload: { data: payload },
            needReadReceipt: true,
        });
        created(message);
        let resp = await this.tim.sendMessage(message);
        if (resp && resp.code === 0) {
            return resp.data.message;
        } else return Promise.reject(resp);
    }
    //发送文本消息
    async sendTextMessage(toUser, text, created = () => {}) {
        if (!this.isSdkReady) return Promise.reject();
        
        const offlinePushInfo = await this.checkUserFollow([toUser]) //默认非好友关系，离线不推送
        
        const message = this.tim.createTextMessage({
            to: toUser,
            conversationType: TencentCloudChat.TYPES.CONV_C2C,
            payload: { text },
            needReadReceipt: true,
        });
        created(message);
        try {
            const resp = await this.tim.sendMessage(message, offlinePushInfo);
            if (resp && resp.code === 0) {
                return resp.data.message;
            }
        } catch (error) {
            // this.store.Log.addImLog({description:'发送私聊消息失败',content:text,error})
            return Promise.reject(error);
        }
    }

    //发送图片消息
    async sendImageMessage(toUser, imagePicker, created = () => {}) {
        if (!this.isSdkReady || !imagePicker) return Promise.reject();

        const offlinePushInfo = await this.checkUserFollow([toUser]) //默认非好友关系，离线不推送
        
        const message = this.tim.createImageMessage({
            to: toUser,
            conversationType: TencentCloudChat.TYPES.CONV_C2C,
            payload: {
                file: imagePicker
            },
        });
        created(message);
        try {
            const resp = await this.tim.sendMessage(message, offlinePushInfo);
            if (resp && resp.code === 0) {
                return resp.data.message;
            }
        } catch (error) {
            // this.store.Log.addImLog({description:'发送图片失败',error})
            return Promise.reject(resp);
        }
    }
    //发送视频消息
    async sendVideoMessage(toUser, videoFile, created = () => {}) {
        if (!this.isSdkReady || !videoFile) return Promise.reject();
        const message = this.tim.createVideoMessage({
            to: toUser,
            conversationType: TencentCloudChat.TYPES.CONV_C2C,
            payload: {
                file: videoFile
            },
            onProgress: function(event) { console.log('file uploading:', event) }
        });
        created(message);
        const resp = await this.tim.sendMessage(message);
        if (resp && resp.code === 0) {
            return resp.data.message;
        } else return Promise.reject(resp);
    }
    init() {
        if (this.initDone) return;
        const { imAppId, logLevel } = this.store;
        this.tim = markRaw(
            TencentCloudChat.create({
                SDKAppID: imAppId,
                unlimitedAVChatRoom: true
            })
        );
        setCustomerServiceAccounts([kfId]);
        this.tim.setLogLevel(logLevel); // 普通级别，日志量较多，接入时建议使用
        // tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
        // this.tim.logout();
        //this.tim.destroy();
        // 注册腾讯云即时通信 IM 上传插件
        this.tim.registerPlugin({ "tim-upload-plugin": TencentCloudChatUploadPlugin });
        // 注册腾讯云即时通信 IM 本地审核插件
        // this.tim.registerPlugin({ "tim-profanity-filter-plugin": TIMProfanityFilterPlugin });
        this.initDone = true;
    }
    //绑定全局事件 -整个生命周期都会用到的事件
    initEvent() {
        if (this.initEventDone) return;
        //连接tim成功
        function onSdkReady(event) {
            console.log("%c IM SDK is ready :>>", "font-size:20px;color:#0969da");
            this.isSdkReady = true;
            this.readyResolve();
        }
        //连接tim成功
        function onSdkNotReady(event) {
            console.log("%c IM SDK is logout :>>", "font-size:20px;color:#0969da");
            this.isSdkReady = false;
            this.loginStatus = null;

            if (this.store.userId) {
                Dialog.confirm({
                    message: "连接已断开，请刷新页面重新连接",
                    confirmButtonText: "刷新",
                    className: "confirm-dialog",
                    closeOnClickOverlay: false,
                    lockScroll: false,
                    teleport: "body",
                })
                    .then(async () => {
                        await this.store.refreshRankList()
                        location.reload();
                    })
                    .catch(() => {});
            }
        }
        //接收消息
        function onMessage(event) {
            MessageHandler(event.data, this.store.currentGroupID);
        }
        //会话列表更新
        function onConversationListUpdate(event) {
            this.store.setConversationList(event.data);
        }
        //绑定到事件 //this.tim.off()取消绑定
        this.tim.on(TencentCloudChat.EVENT.SDK_READY, onSdkReady, this);
        this.tim.on(TencentCloudChat.EVENT.SDK_NOT_READY, onSdkNotReady, this);
        this.tim.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessage, this);
        this.tim.on(TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdate, this);
        this.initEventDone = true;
    }
    //检查用户是否关注
    async checkUserFollow(userIDList){
        if (!this.isSdkReady) return Promise.reject();
        const imResponse = await this.tim.checkFriend({
            userIDList,
            type: TencentCloudChat.TYPES.SNS_CHECK_TYPE_BOTH,
        });
        const { successUserIDList } = imResponse.data;//, failureUserIDList
        const flag = successUserIDList.find(item=> item.relation===TencentCloudChat.TYPES.SNS_TYPE_BOTH_WAY)
        return flag ? {} :{
            androidInfo:{
                VIVOCategory: "IM",
                HuaWeiCategory: "IM",
            },
            offlinePushInfo: {
                disablePush: true // 如果接收方不在线，则消息将存入漫游，但不进行离线推送
            }
        }
    }
    //组件绑定事件  -某个组件需要监听的事件
    useBindEvent(eventName, fuc) {
        function onEvent() {
            fuc.apply(null, arguments);
        }
        onBeforeMount(() => {
            //登录之后绑定事件
            this.loginStatus &&
                this.loginStatus.then(() => {
                    this.tim.on(eventName, onEvent, this);
                });
        });
        onBeforeUnmount(() => {
            this.tim.off(eventName, onEvent, this);
        });
    }

    async login() {
        if (this.isSdkReady) return Promise.reject("重复登录");

        const { userId, userSig,imAppId } = this.store;
        if (!userId || !userSig) return Promise.reject("账号未登录");

        //tim登录之后还不能收发消息，需要等onSdkReady事件回调之后才能使用，所以先新建一个Promise，保存下resolve
        this.loginStatus = new Promise((resolve, reject) => {
            this.readyResolve = resolve;
            this.readyReject = reject;
        });
        try {
            // console.log('登录IM');
            await this.tim.login({ userID: userId, userSig });
            const ui = {
                SDKAppID: imAppId,
                userID: userId,
                userSig,
                useUploadPlugin: true,
                // useProfanityFilterPlugin: true,
                framework: 'vue3'
            }
            // console.log('初始化登录客服IM',ui);
            TUILogin.login(ui)
        } catch (error) {
            // console.error('初始化登录错误：',error)
            this.readyReject(error);
        }
        return this.loginStatus;
    }
    async logout() {
        this.tim
            .logout()
            .then(imResponse => {
                console.log(imResponse.data); // 登出成功
            })
            .catch(imError => {
                console.warn("logout error:", imError);
            })
            .finally(() => {
                this.isSdkReady = false;
            });
    }

    getMyProfile() {
        let promise = this.tim.getMyProfile();
        return promise
            .then(function (imResponse) {
                console.log(imResponse.data); // 个人资料 - Profile 实例
                console.log("imResponse.data :>> ", imResponse.data);
            })
            .catch(function (imError) {
                console.warn("getMyProfile error:", imError); // 获取个人资料失败的相关信息
            });
    }

    //获取消息列表
    getMessageList(config) {
        // 打开某个会话时，第一次拉取消息列表，注意！第一次拉取时不要传入 nextReqMessageID
        let promise = this.tim.getMessageList(config);
        return promise.then(function (imResponse) {
            const messageList = imResponse.data.messageList; // 消息列表。
            const nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
            const isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。isCompleted 为 true 时，nextReqMessageID 为 ""。
            return { messageList, nextReqMessageID, isCompleted };
        });
    }
    //消息置顶操作
    pinConversation(conversationID) {
        let promise = this.tim.pinConversation(conversationID);
        return promise
            .then(function (imResponse) {
                // 置顶会话成功
                // const { conversationID } = imResponse.data; 被置顶的会话 ID
            })
            .catch(function (imError) {
                const { code } = imError;
                // code - 50002 会话 ID 无效
                console.warn("pinConversation error:", imError); // 置顶会话失败的相关信息
            });
    }

    // //发送消息已读回执
    // sendMessageReadReceipt(messageList) {
    //     if (!messageList || !Array.isArray(messageList)) return;
    //     this.tim
    //         .sendMessageReadReceipt(messageList)
    //         .then(function () {
    //             // 发送群消息已读回执成功
    //         })
    //         .catch(function (imError) {
    //             // 发送群消息已读回执失败
    //         });
    // }

    //////////////////
    //删除会话
    deleteConversation(conversationID) {
        let params = conversationID;
        if (Array.isArray(conversationID)) {
            let cid = conversationID;
            if (conversationID.length > 100) {
                cid = conversationID.slice(0, 100);
            }
            params = { conversationIDList: cid };
        }
        // console.log('删除会话参数：====>',params);debugger
        let promise = this.tim.deleteConversation(params);
        promise
            .then(function (imResponse) {
                // 删除会话成功
                const { conversationID } = imResponse.data; // 被删除的会话 ID
                console.log("deleted conversationID :>> ", conversationID);
            })
            .catch(function (imError) {
                console.warn("deleteConversation error:", imError); // 删除会话失败的相关信息
            });
    }

    // 创建直播群
    createGroup(groupID) {
        let promise = this.tim.createGroup({
            type: TencentCloudChat.TYPES.GRP_AVCHATROOM,
            name: "WebSDK",
            groupID,
        });
        return promise
            .then(function (imResponse) {
                // 创建成功
                console.log(imResponse.data.group); // 创建的群的资料
                // 创建群时指定了成员列表，但是成员中存在超过了“单个用户可加入群组数”限制的情况
                // 一个用户 userX 最多允许加入 N 个群，如果已经加入了 N 个群，此时创建群再指定 userX 为群成员，则 userX 不能正常加群
                // SDK 将 userX 的信息放入 overLimitUserIDList，供接入侧处理
                console.log(imResponse.data.overLimitUserIDList); // 超过了“单个用户可加入群组数”限制的用户列表，v2.10.2起支持
            })
            .catch(function (imError) {
                console.warn("createGroup error:", imError); // 创建群组失败的相关信息
            });
    }

    dismissGroup(groupID) {
        let promise = this.tim.dismissGroup(groupID);
        return promise
            .then(function (imResponse) {
                // 解散成功
                console.log(imResponse.data.groupID); // 被解散的群组 ID
            })
            .catch(function (imError) {
                console.warn("dismissGroup error:", imError); // 解散群组失败的相关信息
            });
    }

    getGroupList() {
        // 该接口默认只会拉取这些资料：群类型、群名称、群头像、最后一条消息的时间。
        let promise = this.tim.getGroupList();
        return promise
            .then(function (imResponse) {
                // console.log('群组列表：===>',imResponse.data.groupList); 群组列表
                return imResponse.data.groupList;
            })
            .catch(function (imError) {
                console.warn("getGroupList error:", imError);
                return false; // 获取群组列表失败的相关信息
            });
    }

    // 获取群信息
    getGroupProfile(groupID, groupCustomFieldFilter = []) {
        let promise = this.tim.getGroupProfile({ groupID, groupCustomFieldFilter });
        return promise
            .then(function (imResponse) {
                console.log(imResponse.data.group);
            })
            .catch(function (imError) {
                console.warn("getGroupProfile error:", imError); // 获取群详细资料失败的相关信息
            });
    }
    joinGroup(groupID) {
        let promise = this.tim.joinGroup({ groupID, type: TencentCloudChat.TYPES.GRP_AVCHATROOM });
        return promise
            .then(function (imResponse) {
                switch (imResponse.data.status) {
                    case TencentCloudChat.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
                        break;
                    case TencentCloudChat.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
                        // console.log('加群成功:====>',imResponse.data.group); 加入的群组资料
                        break;
                    case TencentCloudChat.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
                        break;
                    default:
                        break;
                }
                return imResponse.data.status;
            })
            .catch(function (imError) {
                console.warn("joinGroup error:", imError); // 申请加群失败的相关信息
            });
    }
    quitGroup(groupID) {
        let promise = this.tim.quitGroup(groupID);
        return promise
            .then(function (imResponse) {
                // console.log(imResponse.data.groupID); 退出成功的群 ID
                return imResponse.data;
            })
            .catch(function (imError) {
                console.warn("quitGroup error:", imError); // 退出群组失败的相关信息
            });
    }
    /*sendVideoMessage(toUser, fileDom, onProgress) {
        if (!this.isSdkReady) return false;
        const message = this.tim.createVideoMessage({
            to: toUser,
            conversationType: TencentCloudChat.TYPES.CONV_C2C,
            payload: { file: fileDom },
            onProgress,
        });
        this.tim.sendMessage(message);
    }*/
    sendFaceMessage(toUser, index, data) {
        if (!this.isSdkReady) return false;
        const message = this.tim.createFaceMessage({
            to: toUser,
            conversationType: TencentCloudChat.TYPES.CONV_C2C,
            payload: { index, data },
        });
        this.tim.sendMessage(message);
    }
    getTim() {
        return this.tim;
    }
}
